module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"308-electric-kitchen","short_name":"electric-kitchen","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/images/favicon-32x32.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"765b7f22a90e81d6c9de6d2e9a492b43"},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":false,"database":false,"firestore":false,"storage":false,"messaging":false,"functions":false,"performance":false,"analytics":true},"credentials":{"apiKey":"AIzaSyBFgJ7Vw8K0-QYwnUK74_juPCPBbrWLmDU","authDomain":"electric-kitchen.firebaseapp.com","databaseURL":"https://electric-kitchen.firebaseio.com","projectId":"electric-kitchen","storageBucket":"electric-kitchen.appspot.com","messagingSenderId":"153964423895","appId":"1:153964423895:web:061c7a4660f400d2b59e92","measurementId":"G-WYYBT6B884"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
